import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import CardAuthorBox4 from "components/CardAuthorBox4/CardAuthorBox4";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Nav from "shared/Nav/Nav";
import SortOrderFilter from "../SectionGridAuthorBox/SortOrderFilter";

import { Link } from "react-router-dom";
import NextPrev from "shared/NextPrev/NextPrev";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";
import LikeButton from "components/LikeButton";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import { nftsLargeImgs } from "contains/fakeData";
import TimeCountDown from "../CardLarge1/TimeCountDown";
import collectionPng from "images/nfts/collection.png";
import VerifyIcon from "components/VerifyIcon";

import polygonLogo from "images/polygon-matic-logo.png";

export interface SectionGridAuthorBoxProps {
  className?: string;
  sectionStyle?: "style1" | "style2";
  gridClassName?: string;
  boxCard?: "box1" | "box2" | "box3" | "box4";
  data?: any[];
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = ""
}) => {

  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className} flex flex-col items-center`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading
          className="mb-4 text-neutral-900 dark:text-neutral-50 w-full"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          MetaSaga Warriors (Genesis)
      </Heading>
      <div className="mb-14 lg:flex-row lg:items-center">
            <span className="block text-neutral-500 dark:text-neutral-400 text-center">
            Collection Name
            </span>
      </div>
      <Heading
          className="mb-4 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          10,000
      </Heading>
      <div className="mb-12 lg:mb-14 lg:flex-row lg:items-center">
            <span className="block text-neutral-500 dark:text-neutral-400 text-center">
            Supply
            </span>
      </div>
      <Heading
            className="mb-4 text-neutral-900 dark:text-neutral-50"
            fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
            isCenter
            desc=""
          >
            <NcImage className="w-11 ml-auto mr-4" src={polygonLogo} /> Polygon
      </Heading>
      <div className="mb-12 lg:mb-14 lg:flex-row lg:items-center">
            <span className="block text-neutral-500 dark:text-neutral-400 text-center">
            Network
            </span>
      </div>
      <Heading
          className="mb-4 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          March 31, 2023
      </Heading>
      <div className="mb-12 lg:mb-14 lg:flex-row lg:items-center">
            <span className="block text-neutral-500 dark:text-neutral-400 text-center">
            Mint Date
            </span>
      </div>
      
      <div className="w-full mt-12">
        <div className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white dark:bg-neutral-700 shadow-lg rounded-3xl space-y-3 sm:space-y-8 ">
          {/* DESCRIPTION */}
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
            <div className="flex-none w-7/12">
              <h2 className="text-xl font-semibold text-yellow-400">
                Not yet whitelisted?
              </h2>
              <h3 className="text-l font-semibold">
                Don't worry you can still join!
              </h3>
            </div>
            <ButtonPrimary onClick={() => {window.open("https://gleam.io/UPdBN/metasaga-warriors-whitelist-giveaway")}} className="flex-1">
              GO GET WHITELISTED!
            </ButtonPrimary>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SectionGridAuthorBox;
