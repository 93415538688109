import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import { Alchemy, Network } from "alchemy-sdk";
import Web3 from 'web3';

import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";

import Home from "containers/MainPage/Home";
import Profile from "containers/MainPage/Profile";
import Whitelist from "containers/MainPage/WhitelistPage";

export const pages: Page[] = [
  { path: "/", component: Home },
  /*
  { path: "/#", component: PageHome2 },
  { path: "/home2", component: PageHome },
  { path: "/home3", component: PageHome3 },
  { path: "/home-header-2", component: PageHome },
  { path: "/profile", component: Profile },
  { path: "/nft-detailt", component: NftDetailPage },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/page-author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/page-upload-item", component: PageUploadItem },
  { path: "/connect-wallet", component: PageConnectWallet },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  */
  { path: "/whitelist-checker", component: Whitelist}
];

const config = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(config);

const chainId = 137 // Polygon Main Network

const MyRoutes = () => {
  const [account, setAccount] = useState("Wallet not connected"); // state variable to set account.
  const [currentChain, setCurrentChain] = useState(); // state variable to set current chain.
  const web3 = new Web3(Web3.givenProvider); 
  
  const logout = () => {
    setAccount("Wallet not connected");
  }

  const load = async () => {
    const accounts = await web3.eth.requestAccounts();
    setAccount(accounts[0]);

    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(chainId) }]
        });

        setCurrentChain(window.ethereum.networkVersion);

        window.ethereum.on('accountsChanged', function (accounts: any) {
          if (accounts[0] === undefined) setAccount("Wallet not connected");
          else setAccount(accounts[0]);
        })
      } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
        console.log(err)
      }
    }
  }

  useEffect(() => {
    load()
  }, []);

  const siteHeaderProps = { // make sure all required component's inputs/Props keys&types match
    account: account,
    load: load,
    logout: logout
  }

  return (
    <BrowserRouter
      basename=""
    >
      <ScrollToTop />
      <SiteHeader {...siteHeaderProps} />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          const routeProps = { // make sure all required component's inputs/Props keys&types match
            account: account,
            load: load,
            web3: web3,
            alchemy: alchemy
          }
          return <Route key={path} element={<Component {...routeProps} />} path={path}  />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
