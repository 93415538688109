import React, { FC, useEffect, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { ArrowSmRightIcon, SearchIcon } from "@heroicons/react/outline";
import mswHeader from "images/msw_noborder.png";
import { WHITELIST_DATA } from "data/constant";
import Web3 from 'web3';
import { Alchemy, Network } from "alchemy-sdk";

import ReactGA from 'react-ga4';

export interface SectionSubscribe2Props {
    account: string,
    load: any,
    web3: Web3,
    alchemy: Alchemy
  }
  

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({account, load, web3, alchemy}) => {
    const [returnMessage, setReturnMessage] = useState("");
    const [returnMessageClass, setReturnMessageClass] = useState("text-neutral-500 dark:text-neutral-400");
    const [isLoading, setIsLoading] = useState(false);
    const [lastAddress, setLastAddress] = useState("");

    useEffect(() => {
        if(account && account !== lastAddress && account != "Wallet not connected"){
            (document.getElementById("walletAddress") as HTMLInputElement).value = account;
            setLastAddress(account);
            checkWhitelist(account);
        }
    }, [account]);

    const timeout = (delay: number) => {
        return new Promise( res => setTimeout(res, delay) );
    }    

    const checkWhitelist = async (address: string) => {
        setReturnMessageClass("text-neutral-500 dark:text-neutral-400");
        setReturnMessage("Checking whitelist...");
        setIsLoading(true);
        if(!web3.utils.isAddress(address)){
            ReactGA.event({
                category: 'Whitelist Checker',
                action: 'Invalid address',
                label: address,
                value: 0
            });

            setReturnMessageClass("text-red-500 dark:text-red-400")
            setReturnMessage("Invalid address!");
        }
        else{
            const addressLower = address.toLowerCase();
            const whitelist = WHITELIST_DATA;
            const whitelistLower = whitelist.map((address) => address.toLowerCase());
            await timeout(3000);
            if (whitelistLower.includes(addressLower)) {
                ReactGA.event({
                    category: 'Whitelist Checker',
                    action: 'Check Whitelist',
                    label: address,
                    value: 1
                });

                setReturnMessageClass("text-green-500 dark:text-green-400 text-2xl")
                setReturnMessage("You are whitelisted!");
            } else {
                ReactGA.event({
                    category: 'Whitelist Checker',
                    action: 'Check Whitelist',
                    label: address,
                    value: 0
                });

                setReturnMessageClass("text-red-500 dark:text-red-400")
                setReturnMessage("You are not whitelisted.");
            }
        }
        setIsLoading(false);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const address = e.target[0].value;
        checkWhitelist(address);
    }

    return (
    <div
        className={`nc-SectionSubscribe2 relative flex flex-col`}
        data-nc-id="SectionSubscribe2"
    > 
        <div className="lg:flex-row">
            <div className="lg:flex-col">
                <NcImage className="lg:w-2/6 ml-auto mr-auto" src={mswHeader} />
            </div>
            <div className="lg:flex-col">
                <h2 className="font-semibold text-2xl text-center"><span className="text-yellow-400">Check if you're</span> WHITELISTED!</h2>
            </div>
            <div className="lg:flex-col">
                <form className="mt-10 relative lg:w-6/12 xl:w-6/12 2xl:w-6/12 sm:w-full md:w-full ml-auto mr-auto" onSubmit={handleSubmit}>
                    <Input
                        id="walletAddress"
                        required
                        aria-required
                        placeholder="Enter wallet address"
                        rounded="rounded-full"
                    />
                    <ButtonCircle
                        type="submit"
                        className="absolute transform top-1/2 -translate-y-1/2 right-1"
                    >

                        {
                            !isLoading ? <SearchIcon className="h-6 w-6 text-white" /> : 
                            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        
                    </ButtonCircle>
                </form>
            </div>
        </div>  
        <div className="lg:flex-row lg:items-center">
            <span className={`block mt-5 ${returnMessageClass} text-center`}>
            {returnMessage}
            </span>
        </div>

        
    </div>
  );
};

export default SectionSubscribe2;
