import React, { FC, useEffect, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import mswHeader from "images/msw_noborder.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CogIcon } from "@heroicons/react/outline";
import Web3 from 'web3';
import { Alchemy, Network } from "alchemy-sdk";
import ModalMinting from "components/ModalMinting";

import ReactGA from 'react-ga4';
import Countdown from 'react-countdown';

import arbalest from "images/Arbalest.png";
import artillery from "images/Artillery- Coal Punk.png";
import voidmancer from "images/Voidmancer- Wizard.png"

export interface SectionSubscribe2Props {
  account: string,
  load: any,
  web3: Web3,
  alchemy: Alchemy
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({account, load, web3, alchemy}) => {
    const nftContract = (process.env.REACT_APP_SMART_CONTRACT === undefined) ? "" : process.env.REACT_APP_SMART_CONTRACT
    const nftABI: any = [
      {
        "inputs":[
          {
            "internalType":"string",
            "name":"_name",
            "type":"string"
          },
          {
            "internalType":"string",
            "name":"_symbol",
            "type":"string"
          },
          {
            "internalType":"string",
            "name":"_baseURI",
            "type":"string"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"constructor"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"Approval",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
          },
          {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
          }
        ],
        "name":"ApprovalForAll",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"Paused",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "indexed":true,
            "internalType":"bytes32",
            "name":"previousAdminRole",
            "type":"bytes32"
          },
          {
            "indexed":true,
            "internalType":"bytes32",
            "name":"newAdminRole",
            "type":"bytes32"
          }
        ],
        "name":"RoleAdminChanged",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"sender",
            "type":"address"
          }
        ],
        "name":"RoleGranted",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"sender",
            "type":"address"
          }
        ],
        "name":"RoleRevoked",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"Transfer",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"Unpaused",
        "type":"event"
      },
      {
        "inputs":[
          
        ],
        "name":"DEFAULT_ADMIN_ROLE",
        "outputs":[
          {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"MINTER_ROLE",
        "outputs":[
          {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"PAUSER_ROLE",
        "outputs":[
          {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"approve",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"owner",
            "type":"address"
          }
        ],
        "name":"balanceOf",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"burn",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
          }
        ],
        "name":"forceMint",
        "outputs":[
          {
            "internalType":"uint256[]",
            "name":"",
            "type":"uint256[]"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"getApproved",
        "outputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          }
        ],
        "name":"getRoleAdmin",
        "outputs":[
          {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
          }
        ],
        "name":"getRoleMember",
        "outputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          }
        ],
        "name":"getRoleMemberCount",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"grantRole",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"hasRole",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"operator",
            "type":"address"
          }
        ],
        "name":"isApprovedForAll",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"isMintActive",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"isWhitelistOnly",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"limitPerAddress",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"maxSupply",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "name":"mintedPerAddress",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"name",
        "outputs":[
          {
            "internalType":"string",
            "name":"",
            "type":"string"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"ownerOf",
        "outputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"pause",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"paused",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"publicMint",
        "outputs":[
          {
            "internalType":"uint256[]",
            "name":"",
            "type":"uint256[]"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
          }
        ],
        "name":"publicMint",
        "outputs":[
          {
            "internalType":"uint256[]",
            "name":"",
            "type":"uint256[]"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"renounceRole",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes32",
            "name":"role",
            "type":"bytes32"
          },
          {
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"revokeRole",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"from",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"safeTransferFrom",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"from",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          },
          {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
          }
        ],
        "name":"safeTransferFrom",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"operator",
            "type":"address"
          },
          {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
          }
        ],
        "name":"setApprovalForAll",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
          }
        ],
        "name":"supportsInterface",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"symbol",
        "outputs":[
          {
            "internalType":"string",
            "name":"",
            "type":"string"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
          }
        ],
        "name":"tokenByIndex",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
          }
        ],
        "name":"tokenOfOwnerByIndex",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"tokenURI",
        "outputs":[
          {
            "internalType":"string",
            "name":"",
            "type":"string"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"totalSupply",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"from",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"transferFrom",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          
        ],
        "name":"unpause",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"string",
            "name":"baseTokenURI_",
            "type":"string"
          }
        ],
        "name":"updateBaseTokenURI",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bool",
            "name":"_isMintActive",
            "type":"bool"
          }
        ],
        "name":"updateIsMintActive",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"bool",
            "name":"_isWhitelistOnly",
            "type":"bool"
          }
        ],
        "name":"updateIsWhitelistOnly",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"_limitPerAddress",
            "type":"uint256"
          }
        ],
        "name":"updateLimitPerAddress",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"_maxSupply",
            "type":"uint256"
          }
        ],
        "name":"updateMaxSupply",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address[]",
            "name":"_whitelist",
            "type":"address[]"
          },
          {
            "internalType":"bool",
            "name":"_status",
            "type":"bool"
          }
        ],
        "name":"updateWhitelist",
        "outputs":[
          
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "name":"whitelist",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      }
    ]
    const mswContract = new web3.eth.Contract(nftABI, nftContract)

    const [totalSupply, setTotalSupply] = useState("0");
    const [mintLimit, setMintLimit] = useState(0);
    const [mintCounter, setMintCounter] = useState(0);
    const [isMintActive, setIsMintActive] = useState(false);
    const [isMintWhitelistOnly, setIsMintWhitelistOnly] = useState(false);
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [isDoneLoading, setIsDoneLoading] = useState(false);
    
    const [isMinting, setIsMinting] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");

    const openModalMinting = () => setIsMinting(true);
    const closeModalMinting = () => setIsMinting(false);

    const mintNFT = async () => {
      ReactGA.event({
        category: 'Mint',
        action: 'Initialize minting',
        label: String(account),
        value: 1
      });
      
      setTransactionHash("minting");
      openModalMinting();

      const txn = await mswContract.methods
        .publicMint(3)
        .send({ from: account }).then((res: any) => {

          ReactGA.event({
            category: 'Mint',
            action: 'Success upon minting',
            label: String(account),
            value: 3
          });

          setTransactionHash(res.transactionHash)
        }).catch((err: any) => {

          ReactGA.event({
            category: 'Mint',
            action: 'Error upon minting',
            label: String(account),
            value: 3
          });

          setTransactionHash("error")
        })

      setMintCounter(Number(await alchemy.core
        .call({
          to: nftContract,
          data: "0xd445b978000000000000000000000000" + String(account).substring(2)
        })))

      return txn
    }

    const batchMintNFT = async () => {
      ReactGA.event({
        category: 'Mint - DEV',
        action: 'Initialize minting - DEV'
      });

      setTransactionHash("minting");
      openModalMinting();

      const txn = await mswContract.methods
        .publicMint(60)
        .send({ from: account }).then((res: any) => {
          ReactGA.event({
            category: 'Mint - DEV',
            action: 'Success upon minting - DEV'
          });

          setTransactionHash(res.transactionHash)
        }).catch((err: any) => {
          ReactGA.event({
            category: 'Mint - DEV',
            action: 'Error upon minting - DEV'
          });

          setTransactionHash("error")
        })

      setMintCounter(Number(await alchemy.core
        .call({
          to: nftContract,
          data: "0xd445b978000000000000000000000000" + String(account).substring(2)
        })))

      return txn
    }

    const batchMintNFT2 = async () => {
      setTransactionHash("minting");
      openModalMinting();

      const txn = await mswContract.methods
        .publicMint(3)
        .send({ from: account }).then((res: any) => {
          setTransactionHash(res.transactionHash)
        }).catch((err: any) => {
          setTransactionHash("error")
        })

      setMintCounter(Number(await alchemy.core
        .call({
          to: nftContract,
          data: "0xd445b978000000000000000000000000" + String(account).substring(2)
        })))

      return txn
    }

    useEffect(() => {
      async function fetchAlchemy(nftContract: string, setTotalSupply: Function, setIsMintActive: Function, setIsMintWhitelistOnly: Function, setIsDoneLoading: Function, setMintLimit: Function, setMintCounter: Function){
        const metadata = await alchemy.nft.getContractMetadata(nftContract)
        
        setTotalSupply(parseInt(String(await alchemy.core
          .call({
            to: nftContract,
            data: "0x18160ddd"
        }))).toLocaleString('en-US')) 

        setIsMintActive("0x0000000000000000000000000000000000000000000000000000000000000000" == (await alchemy.core
          .call({
            to: nftContract,
            data: "0x5b92ac0d"
        })) ? false : true )
    
        setIsMintWhitelistOnly("0x0000000000000000000000000000000000000000000000000000000000000000" == (await alchemy.core
        .call({
          to: nftContract,
          data: "0x48fece7c"
        })) ? false : true )

        if (account != "Wallet not connected"){
          setIsWhitelisted("0x0000000000000000000000000000000000000000000000000000000000000000" == (await alchemy.core
            .call({
              to: nftContract,
              data: "0x9b19251a000000000000000000000000" + String(account).substring(2)
            })) ? false : true )

          // const list = await alchemy.nft.getNftsForOwner(account, {contractAddresses: [nftContract]});
          // const templist = []
          // for (let x = 0; x < list.ownedNfts.length; x++){
          //   templist.push({title: list.ownedNfts[x].title, image: list.ownedNfts[x].media[0].raw, id: list.ownedNfts[x].tokenId})
          // }

          // console.log(templist)
          // setItems(templist)

          setMintLimit(Number(await alchemy.core
            .call({
              to: nftContract,
              data: "0x2a484ad4"
            })))
          
          setMintCounter(Number(await alchemy.core
            .call({
              to: nftContract,
              data: "0xd445b978000000000000000000000000" + String(account).substring(2)
            })))
        }

        setIsDoneLoading(true);
      }
      
      fetchAlchemy(nftContract, setTotalSupply, setIsMintActive, setIsMintWhitelistOnly, setIsDoneLoading, setMintLimit, setMintCounter)
    }, [nftContract, account, setTotalSupply, setIsMintActive, setIsMintWhitelistOnly, setIsDoneLoading, setMintLimit, setMintCounter])
    
    const title = [
        "Minting for MSW Collection will be available soon", 
        "Exclusive minting for MSW Collection is now available!",
        "Public minting for MSW Collection is now available!",
        "Minting for MSW is not yet available",
    ]

    const description = [
        "Minting will be available soon. Subscribe to our super-exclusive drop list and be the first to know about upcoming drops",
        "Whitelist minting is currently ongoing. If the button below doesn't show up, please wait for the public minting to start",
        "Public minting is currently ongoing. First come, first serve!",
        "Minting is not yet available. Subscribe to our super-exclusive drop list and be the first to know about upcoming drops"
    ]

    function MintButton() {
      if (account == "Wallet not connected" || !isDoneLoading) {
        return (
          <div>
          </div>
        )
      }
      
      if (isMintActive && (isWhitelisted && isMintWhitelistOnly || !isMintWhitelistOnly)) {
        if (mintCounter < mintLimit && Number(totalSupply.replace(/\,/g, '')) + 1 <= 7000) {
          return (
            <div className="">
              <span className="block mt-5 font-semibold text-yellow-300 text-neutral-500 dark:text-neutral-400 text-center">
                {parseInt(String(10000-Number(totalSupply.replace(/\,/g, '')))).toLocaleString('en-US') + " of 10,000 MSW NFTs remaining"}
              </span>
              <ul className="space-y-4 mt-5">
                <ButtonPrimary onClick={mintNFT} className="bg-purple-500 w-full">
                  <span className="text-indigo-100 font-semibold">{"MINT NOW! (3 NFTS)"}</span>
                </ButtonPrimary>
              </ul>
            </div>
          )
        }
        else{
          if(Number(totalSupply.replace(/\,/g, '')) + 1 >= 7000){
            return (
              <div>
                <span className="block mt-5 font-semibold text-yellow-300 text-neutral-500 dark:text-neutral-400 text-center">
                  {parseInt(String(10000-Number(totalSupply.replace(/\,/g, '')))).toLocaleString('en-US') + " of 10,000 MSW NFTs remaining"}
                </span>
                <ul className="space-y-4 mt-5">
                  <ButtonPrimary className="bg-purple-500 w-full">
                    <span className="text-indigo-100 font-semibold">{"Reserve limit reached"}</span>
                    <CogIcon className="w-5 h-5 ml-2.5" />
                  </ButtonPrimary>
                </ul>
              </div>
            )
          }
          else {
            return (
              <div>
                <span className="block mt-5 font-semibold text-yellow-300 text-neutral-500 dark:text-neutral-400 text-center">
                  {parseInt(String(10000-Number(totalSupply.replace(/\,/g, '')))).toLocaleString('en-US') + " of 10,000 MSW NFTs remaining"}
                </span>
                <ul className="space-y-4 mt-5">
                  <ButtonPrimary className="bg-purple-500 w-full">
                    <span className="text-indigo-100 font-semibold">{"Mint limit reached"}</span>
                    <CogIcon className="w-5 h-5 ml-2.5" />
                  </ButtonPrimary>
                </ul>
              </div>
            )
          }
        }
      }

      if (isMintActive && isMintWhitelistOnly && !isWhitelisted) {
        return (
          <div>
            <ul className="space-y-4 mt-5">
              <ButtonPrimary className="bg-purple-500 w-full">
                <span className="text-indigo-100 font-semibold">{"You are not whitelisted."}</span>
                <CogIcon className="w-5 h-5 ml-2.5" />
              </ButtonPrimary>
            </ul>
          </div>
        )
      }

      if (Date.now() <= 1680220800000) {
        return (
          <div>
            <ul className="space-y-4 mt-5">
            <ButtonPrimary className="bg-purple-500 w-full">
                <span className="text-indigo-100 font-semibold">
                  <Countdown date={1680220800000} />
                </span>
                &nbsp; Until Minting
              </ButtonPrimary>
            </ul>
          </div>
        )
      }
      return (
        <div>
          <ul className="space-y-4 mt-5">
          <ButtonPrimary className="bg-purple-500 w-full">
              <span className="text-indigo-100 font-semibold">{"Mint ended"}</span>
              <CogIcon className="w-5 h-5 ml-2.5" />
            </ButtonPrimary>
          </ul>
        </div>
      )
    }

    return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center`}
      data-nc-id="SectionSubscribe2"
    >
      <ModalMinting show={isMinting} onCloseModalDelete={closeModalMinting} transactionHash={transactionHash} />
      <div className="flex-shrink-0 pt-0 mt-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <div className="flex-grow">
          <NcImage src={mswHeader} />
        </div>
        <h2 className="font-bold text-4xl text-blue-400 italic text-center">GENESIS COLLECTION</h2>
        <MintButton />
        <span className="block text-yellow-400 mt-5 text-neutral-500 dark:text-neutral-400 italic text-center">
          *Max 3 NFTs per wallet
        </span>
        <span className="block text-yellow-400 text-neutral-500 dark:text-neutral-400 italic text-center">
          Mint price: FREE
        </span>
        <span className="block text-yellow-400 text-neutral-500 dark:text-neutral-400 italic text-center">
          Network: Polygon
        </span>
      </div>
      <div className="flex ">
        <NcImage className="w-full scale-150 lg:translate-x-16" src={arbalest} />
        <NcImage className="w-full scale-150 lg:translate-x-56" src={artillery} />
        <NcImage className="w-full scale-150 lg:translate-y-20 lg:-translate-x-48" src={voidmancer} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
