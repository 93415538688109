import React from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "../PageHome/SectionVideos";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "../PageHome/SectionLargeSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionGridFeatureNFT2 from "../PageHome/SectionGridFeatureNFT2";
import SectionMagazine8 from "components/SectionMagazine8";
import SectionSliderCardNftVideo from "components/SectionSliderCardNftVideo";
import SectionSliderCollections2 from "components/SectionSliderCollections2";

import WhitelistSection from "components/MainSection/WhitelistSection";
import MintDetailsSection from "components/MainSection/MintDetailsSection";

import ReactGA from 'react-ga4';

function PageHome(props: any) {
  ReactGA.send({ hitType: "pageview", page: "/whitelist-checker", title: "Whitelist Validation Page" });

  if (process.env.REACT_APP_IS_WHITELIST_PAGE_OPEN != "true") window.open("https://metasagawarriors.com/","_self")

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>MetaSaga Warriors || Whitelist Validation Page</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative mt-5 mb-20 sm:mb-24 lg:mt-20 lg:mb-32">
        <WhitelistSection {...props} />
      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        <div className="relative py-20 lg:py-28">
          <MintDetailsSection/>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
