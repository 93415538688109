import React from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";

const SiteHeader = (props: any) => {
  let location = useLocation();

  if (props.account != "Wallet not connected") {
    return <HeaderLogged {...props}/>
  }
  else {
    return <Header2 {...props}/>
  }
};

export default SiteHeader;
