import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal2";

export interface ModalMintingProps {
  show: boolean;
  onCloseModalDelete: () => void;
  transactionHash: string;
}

const ModalMinting: FC<ModalMintingProps> = ({ show, onCloseModalDelete, transactionHash }) => {

    const [hideCloseButton, setHideCloseButton] = useState(true);

    const handleClickSubmitForm = () => {
        console.log({ 1: "1" });
    };

    function message() {
        setHideCloseButton(true)
        if (transactionHash == "minting") {
            return (
                <div>
                    <span className="text-sm">
                        Confirm the transaction in your wallet
                    </span>
                </div>
            )
        }
        else if (transactionHash == "error") {
            setHideCloseButton(false);
            return (
                <div>
                    <span className="text-sm">
                        There was an error with the transaction
                    </span>
                    <div className="mt-4 space-x-3">
                        <ButtonSecondary type="button" onClick={onCloseModalDelete}>
                            Close
                        </ButtonSecondary>
                    </div>
                </div>
            )
        }
        else {
            setHideCloseButton(false);
            return (
                <div>
                    <span className="text-sm">
                        Mint in progress. Transaction hash: <a href={process.env.REACT_APP_EXPLORER_URL + "tx/" + transactionHash}>{transactionHash}</a>
                    </span>
                    <div className="mt-4 space-x-3">
                        <ButtonSecondary type="button" onClick={onCloseModalDelete}>
                            Close
                        </ButtonSecondary>
                    </div>
                </div>
            )
        }
    }

    const renderContent = () => {
        return (
        <form action="#">
            {message()}
        </form>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <NcModal
        isOpenProp={show}
        onCloseModal={onCloseModalDelete}
        contentExtraClass="max-w-screen-sm"
        renderContent={renderContent}
        renderTrigger={renderTrigger}
        modalTitle=""
        hideCloseButton={hideCloseButton}
        />
    );
};

export default ModalMinting;