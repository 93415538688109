import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Navigation from "shared/Navigation/Navigation";

const MainNav2 = (props: any) => {
  const hoverMintPage = (window.location.pathname === "/") ? "hover:bg-neutral-500 dark:hover:bg-neutral-500" : ""
  const buttonLinkClass = (window.location.pathname === "/") ? "fill-white stroke-white dark:fill-white dark:stroke-white" : "fill-dark stroke-dark dark:fill-white dark:stroke-white"

  return (
    <div className={`nc-MainNav2 relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <button 
              onClick={() => { window.location.href = "https://discord.com/invite/5mDJmv7su3" }}
              className={hoverMintPage + "text-2xl md:text-3xl w-12 h-12 rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center"}>
              <svg 
                className={buttonLinkClass}
                width="24"
                height="24"
                viewBox="0 -28.5 256 256" 
                xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> 
                    <g> 
                      <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" fill-rule="nonzero"></path> 
                    </g>
                  </g>
              </svg>
            </button>
            <button 
              onClick={() => { window.location.href = "https://twitter.com/metasagawarrior" }}
              className={hoverMintPage + "text-2xl md:text-3xl w-12 h-12 rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center"}>
              <svg 
                className={buttonLinkClass}
                width="24"
                height="24"
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 512 512">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier"> 
                  <g id="7935ec95c421cee6d86eb22ecd12f847"> 
                    <path d="M459.186,151.787c0.203,4.501,0.305,9.023,0.305,13.565 c0,138.542-105.461,298.285-298.274,298.285c-59.209,0-114.322-17.357-160.716-47.104c8.212,0.973,16.546,1.47,25.012,1.47 c49.121,0,94.318-16.759,130.209-44.884c-45.887-0.841-84.596-31.154-97.938-72.804c6.408,1.227,12.968,1.886,19.73,1.886 c9.55,0,18.816-1.287,27.617-3.68c-47.955-9.633-84.1-52.001-84.1-102.795c0-0.446,0-0.882,0.011-1.318 c14.133,7.847,30.294,12.562,47.488,13.109c-28.134-18.796-46.637-50.885-46.637-87.262c0-19.212,5.16-37.218,14.193-52.7 c51.707,63.426,128.941,105.156,216.072,109.536c-1.784-7.675-2.718-15.674-2.718-23.896c0-57.891,46.941-104.832,104.832-104.832 c30.173,0,57.404,12.734,76.525,33.102c23.887-4.694,46.313-13.423,66.569-25.438c-7.827,24.485-24.434,45.025-46.089,58.002 c21.209-2.535,41.426-8.171,60.222-16.505C497.448,118.542,479.666,137.004,459.186,151.787z"> </path> 
                  </g>
                </g>
              </svg>
            </button>
            <button 
              onClick={() => { window.location.href = "https://www.facebook.com/metasagawarriors" }}
              className={hoverMintPage + "text-2xl md:text-3xl w-12 h-12 rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center"}>
              <svg 
                className={buttonLinkClass}
                width="24"
                height="24"
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg" >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> 
                    <path d="M12 2.03998C6.5 2.03998 2 6.52998 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.84998C10.44 7.33998 11.93 5.95998 14.22 5.95998C15.31 5.95998 16.45 6.14998 16.45 6.14998V8.61998H15.19C13.95 8.61998 13.56 9.38998 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96C15.9164 21.5878 18.0622 20.3855 19.6099 18.57C21.1576 16.7546 22.0054 14.4456 22 12.06C22 6.52998 17.5 2.03998 12 2.03998Z"></path> 
                  </g>
              </svg>
            </button>
            {
              (window.location.pathname !== "/") && (
                <SwitchDarkMode />
              )
            }
            <ButtonSecondary
              sizeClass="px-4 py-2 sm:px-5"
              onClick={props.load}
            >
              Connect Wallet
            </ButtonSecondary>
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            <ButtonSecondary
                sizeClass="px-4 py-2 sm:px-5"
                onClick={props.load}
              >
              Connect Wallet
            </ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
