import React from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "../PageHome/SectionVideos";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "../PageHome/SectionLargeSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeatureNFT2 from "../PageHome/SectionGridFeatureNFT2";
import SectionMagazine8 from "components/SectionMagazine8";
import SectionSliderCardNftVideo from "components/SectionSliderCardNftVideo";
import SectionSliderCollections2 from "components/SectionSliderCollections2";

import MintSection from "components/MainSection/MintSection";
import { AnyARecord } from "dns";

import ReactGA from 'react-ga4';

function PageHome(props: any) {
  ReactGA.send({ hitType: "pageview", page: "/", title: "NFT Minting Page" });

  if (process.env.REACT_APP_IS_MINTING_PAGE_OPEN != "true") window.open("https://metasagawarriors.com/","_self")
  
  return (
    <div className="nc-PageHome relative overflow-hidden h-screen">
      <Helmet>
        <title>MetaSaga Warriors || NFT Minting Page</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      
      <div className="container relative">

        {/* SECTION */}
        <div className="relative">
          <MintSection {...props}/>
        </div>

        {/* SECTION */}
      </div>
    </div>
  );
}

export default PageHome;
